/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "@fontsource/abril-fatface"
import "@fontsource/nunito-sans"

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={"w-full mx-auto relative"}>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main>{children}</main>
      <footer
        className={"bg-black py-10 px-8 sm:px-28 lg:px-28 xl:px-60 mx-auto"}
      >
        <span className={"text-gray-300 block text-center text-sm"}>
          Copyright © {new Date().getFullYear()} Allan Kiezel – Long Island Web
          Designer & App Developer. All rights reserved.
        </span>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
