import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import logo from "../images/logo.svg"

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      wordpressSiteMetadata {
        name
      }
      wordpressWpApiMenusMenusItems(name: { eq: "Main Menu" }) {
        items {
          title
          object_slug
          url
        }
      }
    }
  `)

  return (
    <header className={"absolute top-0 w-full z-100"}>
      <div
        className={
          "container mx-auto flex justify-between items-center px-6 py-4"
        }
      >
        <Link to="/">
          <img className={"h-10"} src={logo} alt="Allan Kiezel"></img>
        </Link>

        {/* <button
          className={"hidden list-none m-0"}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <svg viewBox="0 0 100 80" width="30" height="30">
            <rect width="100" height="20" rx="8"></rect>
            <rect y="30" width="100" height="20" rx="8"></rect>
            <rect y="60" width="100" height="20" rx="8"></rect>
          </svg>
        </button> */}

        <ul
          className={`flex flex-wrap list-none m-0 ${
            menuOpen ? "flex-important" : ""
          }`}
        >
          {data.wordpressWpApiMenusMenusItems.items.map((item, idx) => (
            <li
              key={item.object_slug}
              className={`sm:inline-block my-0 mx-2.5 ${
                idx < 3 ? "hidden" : ""
              }`}
            >
              <a href={`${item.url}`} className={"text-gray-900 no-underline"}>
                {item.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </header>
  )
}

export default Header
