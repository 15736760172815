/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useSiteMetadata } from "../hooks/use-site-metadata"

function SEO({ description, lang, meta, title, yoastTitle, yoastMeta }) {
  if (!yoastMeta) {
    const site = useSiteMetadata()

    const metaDescription = description || site.description
    const metaTitle = title || site.title
    const defaultTitle = site?.title

    return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={metaTitle}
        titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site?.author || ``,
          },
          {
            name: `twitter:title`,
            content: metaTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
      />
    )
  } else {
    const meta = yoastMeta
      .filter(metaItem => {
        const metaName = metaItem.property || metaItem.name
        return metaName !== "robots" && metaName !== "robots"
      })
      .map(metaItem => {
        const metaName = metaItem.property || metaItem.name
        return {
          name: metaName,
          content: metaItem.content
            .replace("editor.", "")
            .replace("http://", "https://"),
        }
      })

    return <Helmet title={yoastTitle} meta={meta}></Helmet>
  }
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  yoastTitle: null,
  yoastMeta: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  yoastTitle: PropTypes.string,
  yoastMeta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
